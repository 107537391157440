import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/system';

const cofounders = [
    {
        avatar: <Avatar alt="Sethuram Senthilkumar" src="/static/images/avatar/1.jpg" />,
        name: 'Sethuram Senthilkumar',
        occupation: 'Co Founder',
        testimonial:
            "Sethu, a dynamic entrepreneur and co-founder of Sept 16th Pte Ltd, is driven by a desire to improve lives through technology. With expertise in energy efficiency and a commitment to sustainability, Sethu works alongside Akshay to turn ideas into impactful realities.",
    },
    {
        avatar: <Avatar alt="Akshay Krishna" src="/static/images/avatar/2.jpg" />,
        name: 'Akshay Krishna',
        occupation: 'Co Founder',
        testimonial:
            "Akshay, a tech enthusiast and visionary leader, co-founded Sept 16th Pte Ltd to bring innovative solutions to life. With a background in software development and a passion for innovative technology, Akshay leads the charge in creating products that make a difference.",
    },
];



export default function About() {
    const theme = useTheme();

    return (
        <Box
            id="about"
            sx={{
                color: 'white',
                backgroundColor: theme.palette.primary.dark,
            }}
        >
            <Container
                id="about"
                sx={{
                    pt: { xs: 4, sm: 12 },
                    pb: { xs: 8, sm: 16 },
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: { xs: 3, sm: 6 },
                }}
            >
                <Box
                    sx={{
                        width: { sm: '100%', md: '60%' },
                        textAlign: { sm: 'left', md: 'center' },
                    }}
                >
                    <Typography component="h2" variant="h4" color="text.primary">
                        About
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        Sept 16th Pte Ltd is dedicated to creating innovative solutions that enhance lives sustainably. Our journey begins with Graduate Guru, an edtech platform designed to revolutionize the way people learn and grow.
                    </Typography>
                </Box>
                <Grid
                    container
                    spacing={2}
                    sx={{
                        justifyContent: 'center',
                    }}
                >
                    {cofounders.map((cofounder, index) => (
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            key={index}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Card
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    flexGrow: 1,
                                    p: 1,
                                }}
                            >
                                <CardContent>
                                    <Typography variant="body2" color="text.secondary">
                                        {cofounder.testimonial}
                                    </Typography>
                                </CardContent>
                                <CardHeader
                                    avatar={cofounder.avatar}
                                    title={cofounder.name}
                                    subheader={cofounder.occupation}
                                />
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
}
