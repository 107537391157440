import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/X';
import IconButton from '@mui/material/IconButton';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const tiers = [
    {
        title: 'LinkedIn',
        icon: <LinkedInIcon />,
        buttonVariant: 'outlined',
    },
    {
        title: 'Email us',
        icon: <MailOutlineIcon />,
        buttonVariant: 'outlined',
    },
    {
        title: 'X',
        icon: <TwitterIcon />,
        buttonVariant: 'outlined',
    },
];

/**
 * Component for the Contact section of the website.
 * @returns {JSX.Element} The Contact section.
 */
export default function Contact() {
    return (
        // Container for the About section
        <Container
            id="contact"
            sx={{
                pt: { xs: 8, sm: 16 }, // Padding top for different screen sizes
                pb: { xs: 8, sm: 16 }, // Padding bottom for different screen sizes
                position: 'relative', // Position of the container
                display: 'flex', // Display as a flex container
                flexDirection: 'column', // Flex direction is column
                alignItems: 'center', // Align items to the center
                gap: { xs: 3, sm: 6 }, // Gap between items for different screen sizes
            }}
        >
            <Box
                sx={{
                    width: { sm: '100%', md: '60%' }, // Width for different screen sizes
                    textAlign: { sm: 'left', md: 'center' }, // Text alignment for different screen sizes
                }}
            >
                <Typography component="h2" variant="h4" color="text.primary">
                    Contact
                </Typography>
                <Typography variant="body1" color="text.secondary">
                    We'd love to hear from you! Reach out with any questions, feedback, or partnership inquiries.
                </Typography>
            </Box>
            <Grid
                container
                alignItems="center"
                justifyContent="center"
                sx={{
                    gap: 2, // Gap between items
                    p: 2, // Padding for the container
                }}
            >
                {tiers.map((tier) => (
                    <Grid item xs={12} sm={4} md={2} key={tier.title}>
                        <IconButton
                            color="inherit"
                            href="mailto:sales.sept16th@gmail.com"
                            aria-label={tier.title}
                            sx={{ alignSelf: 'center' }}
                        >
                            {tier.icon}
                        </IconButton>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}
