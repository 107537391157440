import * as React from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AppNavBar from './components/AppAppBar';
import Contact from './components/Contact';
import Products from './components/Products';
import About from './components/About';
import getLPTheme from './getLPTheme';

export default function LandingPage() {
    const mode = 'dark';
    const LPtheme = createTheme(getLPTheme(mode));

    return (
        <ThemeProvider theme={LPtheme}>
            <CssBaseline />
            <AppNavBar />
            <Box sx={{ bgcolor: 'background.default' }}>
                <Products />
                <Divider />
                <About />
                <Divider />
                <Contact />
            </Box>
        </ThemeProvider>
    );
}