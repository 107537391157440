import * as React from 'react';

import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';

function AppNavBar() {
    const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        setIsDrawerOpen(newOpen);
    };

    const scrollToSection = (sectionId) => {
        const sectionElement = document.getElementById(sectionId);
        if (sectionElement) {
            sectionElement.scrollIntoView({ behavior: 'smooth' });
            window.scrollTo({
                top: sectionElement.offsetTop - 128,
                behavior: 'smooth',
            });
            setIsDrawerOpen(false);
        }
    };

    return (
        <div>
            <AppBar
                position="fixed"
                sx={{
                    boxShadow: 0,
                    bgcolor: 'transparent',
                    backgroundImage: 'none',
                    mt: 2,
                }}
            >
                <Container maxWidth="lg">
                    <Toolbar
                        variant="regular"
                        sx={(theme) => ({
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            flexShrink: 0,
                            borderRadius: '999px',
                            bgcolor: theme.palette.mode === 'light' ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.4)',
                            backdropFilter: 'blur(24px)',
                            maxHeight: 40,
                            border: '1px solid',
                            borderColor: 'divider',
                            boxShadow: theme.palette.mode === 'light' ? '0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)' : '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
                        })}
                    >
                        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', ml: '-18px', px: 0 }}>
                            <Typography component="h3" variant="h5" noWrap sx={{ margin: 5, color: (theme) => theme.palette.mode === 'light' ? 'black' : 'white' }}>
                                sept16th
                            </Typography>
                            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                                <MenuItem onClick={() => scrollToSection('products')} sx={{ py: '6px', px: '12px' }}>
                                    <Typography variant="body2" color="text.primary">Products</Typography>
                                </MenuItem>
                                <MenuItem onClick={() => scrollToSection('about')} sx={{ py: '6px', px: '12px' }}>
                                    <Typography variant="body2" color="text.primary">About</Typography>
                                </MenuItem>
                                <MenuItem onClick={() => scrollToSection('contact')} sx={{ py: '6px', px: '12px' }}>
                                    <Typography variant="body2" color="text.primary">Contact</Typography>
                                </MenuItem>
                            </Box>
                        </Box>
                        <Box sx={{ display: { sm: '', md: 'none' } }}>
                            <Button variant="text" color="primary" aria-label="menu" onClick={toggleDrawer(true)} sx={{ minWidth: '30px', p: '4px' }}>
                                <MenuIcon />
                            </Button>
                            <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
                                <Box sx={{ minWidth: '60dvw', p: 2, backgroundColor: 'background.paper', flexGrow: 1 }}>
                                    <MenuItem onClick={() => scrollToSection('products')}>Products</MenuItem>
                                    <MenuItem onClick={() => scrollToSection('about')}>About</MenuItem>
                                    <MenuItem onClick={() => scrollToSection('contact')}>Contact</MenuItem>
                                </Box>
                            </Drawer>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </div>
    );
}

AppNavBar.propTypes = {
};

export default AppNavBar;